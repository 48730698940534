import { StaticImageData } from "next/image";
import demoWorkPlace from "static/demos/demoWorkPlace.jpg";
import demoUroki from "static/demos/demoUroki.png";
import kupipetPreview from "static/demos/kupipet.jpg";
import rootpayPreview from "static/demos/rootpay.jpg";
import dottPreview from "static/demos/dott.jpg";
import ngenixPreview from "static/demos/ngenix.jpg";
import armadaPreview from "static/demos/armada.jpg";

type Demo = {
  name: string;
  description: string;
  link: string;
  imageLink: StaticImageData;
};
export const demos: Demo[] = [
  {
    name: "Сайт приложение для записи на уроки. (2024)",
    description:
      "Показываю демонстрационное приложение, где учителя могут создавать расписание, делиться ссылкой и пользователи могут могут просмотреть расписание и записаться на уроки. Вход сделан через Гугл и Яндкекс",
    link: "https://uroki.kododel.ru",
    imageLink: demoUroki,
  },
  {
    name: "Телеграм приложение для подработок в деревне. (2024)",
    description:
      "Показываю демонстрационное приложение, где пользователи могут получать подработки через чат и публиковать подработки для других через приложение. Сделан вход через телеграм на сайте или через приложение в боте.",
    link: "https://work-place.kododel.ru",
    imageLink: demoWorkPlace,
  },
  {
    name: "Разработка фронтенда сервиса по макетам для поиска домашних питомцев (2018).",
    description: `Для проекта заказчик предоставил адаптивные макеты для 6 разрешений: - Широкий монитор - Узкий монитор - Планшет - Узкий планшет - Широкое мобильное устройство - Мобильное устройство

Количество страниц: 38

Задача выполнена за: 14 дней

Фронтенд выполнен в компонентном подходе.`,
    link: "https://works.kododel.ru/kupipet",
    imageLink: kupipetPreview,
  },
  {
    name: "Разработка по макетам фронтенда многостраничного сайта для выплат вебмастерам и фрилансерам.(2019)",
    description: `Количество страниц: 11

Основная задача: разработать на русском и английском языке.`,
    link: "https://works.kododel.ru/rootpay",
    imageLink: rootpayPreview,
  },
  {
    name: `Разработка многостраничного сайта по макетам для компании предоставляющей телекоммуникационные услуги. (2019)`,
    description: `Сайт сделан с резиновой версткой`,
    link: "https://works.kododel.ru/dott",
    imageLink: dottPreview,
  },
  {
    name: `Разработка многостраничного сайта по макетам для компании предоставляющей юридические услуги. (2019)`,
    description: `Для проекта заказчик предоставил видео анимации загрузки главной страницы, а также видео анимации переходов между страницами. На главной странице реализована анимация линии при скролле вниз.

Также для проекта заказчик предоставил макеты для 3 разрешений

Количество страниц: 10

Фронтенд выполнен в компонентном подходе.`,
    link: "https://works.kododel.ru/armada",
    imageLink: armadaPreview,
  },
  {
    name: `Разработка многостраничного сайта по макетам для распределенной облачной платформы на cms WordPress.`,
    description: `Разработка велась до 2021 года`,
    link: "https://ngenix.net/",
    imageLink: ngenixPreview,
  },
];
